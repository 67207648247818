<template>
    <div
        class="data-table"
        :class="{
            'data-table_type_overflow': overflow
        }"
    >
        <div
            v-if="filterable"
            class="data-table__filter"
        >
            <Input
                :value="filterBy"
                :placeholder="filterPlaceholder || ''"
                :onChange="changeFilterState"
            />
        </div>

        <div
            v-if="showPaginationInfo"
            class="data-table__pagination-info"
        >
            {{ paginationInfoMessage }}
        </div>

        <div
            class="data-table__content"
            @scroll="onScroll"
        >
            <div class="data-table__progress-bar">
                <template v-if="loading">
                    <div class="data-table__progress-bar-background" />
                    <div class="data-table__progress-bar-line" />
                    <div class="data-table__progress-bar-line data-table__progress-bar-line_type_short" />
                </template>
            </div>

            <template v-if="headers">
                <div
                    class="data-table__row  data-table__row_type_header"
                    :class="{
                        'data-table__row_type_sticky': stickyHeader
                    }"
                >
                    <div
                        v-for="({text, value, width, sortable}) in headers"
                        :key="value"
                        class="data-table__cell"
                        :class="{
                            'data-table__cell_type_custom-width': width,
                            'data-table__cell_type_sortable': sortable
                        }"
                        :style="width ? `flex-basis: ${width}` : ''"
                        @click="sortable ? changeSortState(value) : {}"
                    >
                        <slot
                            :name="`header-${value}`"
                            :data="{text, value}"
                        />
                        <template v-if="!$slots[`header-${value}`]">
                            {{text}}
                        </template>
                        <i
                            v-if="sortable"
                            class="sort-icon"
                            :class="[
                                {'active': sortBy === value},
                                sortBy === value ? sortDirection : initialSortDirection
                            ]"
                        />
                    </div>
                </div>
            </template>

            <template v-if="itemsToDisplay.length">
                <div
                    v-for="(item, index) in itemsToDisplay"
                    :key="index"
                    class="data-table__row"
                    :class="{
                        'data-table__row_type_clickable': onRowClick
                    }"
                    @click="onRowClick ? onRowClick($event, item) : {}"
                >
                    <div
                        v-for="({ value, width }) in headers"
                        :key="value + index"
                        class="data-table__cell"
                        :class="{
                            'data-table__cell_type_custom-width': width
                        }"
                        :style="width ? `flex-basis: ${width}` : ''"
                    >
                        <slot
                            :name="value"
                            :item="item"
                        />
                        <template v-if="!$slots[value]">
                            {{ item[value] }}
                        </template>
                    </div>
                </div>
            </template>

            <template v-else>
                <div class="data-table__row">
                    <div class="data-table__cell">
                        <div class="data-table__no-items">
                            No {{ itemsName || 'items' }} to display
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script src="./DataTable.js"></script>

<style
    src="./DataTable.scss"
    lang="scss"
    scoped
></style>
