export default {
    name: 'ContextMenuItem',

    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        onClick: {
            type: Function,
            default: () => {}
        }
    }
};
