export default {
    name: 'Input',

    inheritAttrs: false,

    props: {
        value: {
            type: String
        },
        type: {
            type: String,
            default: 'text'
        },
        name: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ''
        },
        hint: {
            type: String,
            default: null
        },
        tabindex: {
            type: String,
            default: null
        },
        onChange: {
            type: Function,
            required: true
        },
        onFocus: {
            type: Function,
            default: () => {}
        },
        onBlur: {
            type: Function,
            default: () => {}
        },
        onKeyPress: {
            type: Function,
            default: null
        },
        onEnterKeyPress: {
            type: Function,
            default: null
        }
    },

    data() {
        return {
            id: `input-${Math.random().toString(36).substring(2, 10)}`
        };
    },

    methods: {
        onKeyUp(event) {
            if (this.onKeyPress) {
                this.onKeyPress(event);
            }

            if (this.onEnterKeyPress) {
                const { keyCode } = event;
                const enterKey = keyCode === 13;

                if (enterKey) {
                    this.onEnterKeyPress(event);
                }
            }
        }
    }

};
