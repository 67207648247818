export default {
    name: 'Checkbox',

    props: {
        modelValue: [String, Array],
        value: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        checked: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
        onChange: {
            type: Function,
            default: null
        }
    },

    emits: ['update:modelValue'],

    computed: {
        model: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },

    setup() {
        const id = `select-${Math.random().toString(36).substring(2, 10)}`;

        return { id };
    },

    methods: {
        onCheckboxChange(event) {
            if (this.disabled) {
                return;
            }

            if (this.onChange) {
                this.onChange(event);
            }
        }
    }
};
