<template>
    <div
        class="checkbox"
        :class="{
            checkbox_state_disabled: disabled
        }"
    >
        <div class="checkbox__field-container">
            <input
                v-model="model"
                class="checkbox__field"
                :id="id"
                type="checkbox"
                :name="name"
                :value="value"
                :checked="checked"
                :disabled="disabled"
                @change="onCheckboxChange"
            />

            <div class="checkbox__fake"></div>
        </div>

        <div
            v-if="label"
            class="checkbox__label-container"
        >
            <label
                class="checkbox__label"
                :for="id"
            >
                {{ label }}
            </label>
        </div>

        <slot />
    </div>
</template>

<script src="./Checkbox.js"></script>

<style
    src="./Checkbox.scss"
    lang="scss"
    scoped
></style>
