import {
    ref,
    computed
} from 'vue';

export default {
    name: 'Toggler',

    props: {
        id: {
            type: String,
            default: null
        },
        opened: {
            type: Boolean,
            default: false
        },
        show: {
            type: Boolean,
            default: null
        },
        showButtonText: {
            type: String,
            default: 'Show'
        },
        hideButtonText: {
            type: String,
            default: 'Hide'
        },
        onChange: {
            type: Function,
            default: null
        }
    },

    setup(props) {
        const localState = ref(props.opened);
        const showContent = computed(() => {
            if (props.show !== null) {
                return props.show;
            }

            return localState.value;
        });
        const onTriggerClick = () => {
            localState.value = !localState.value;

            if (props.onChange) {
                props.onChange(props.id);
            }
        };

        return {
            showContent,
            onTriggerClick
        };
    }
};
