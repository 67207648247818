<template>
    <div class="pagination">
        <div
            class="pagination__navigation-button"
            :class="{
                'pagination__navigation-button_type_hidden': onlyOnePage || isFirstPage,
                'pagination__navigation-button_type_disabled': disabled
            }"
            @click="() => { onChange(page - 1); }"
        />

        <div class="pagination__text">
            {{ paginationMessage }}
        </div>

        <div
            class="pagination__navigation-button  pagination__navigation-button_type_next"
            :class="{
                'pagination__navigation-button_type_hidden': onlyOnePage || isLastPage,
                'pagination__navigation-button_type_disabled': disabled
            }"
            @click="() => { onChange(page + 1); }"
        />
    </div>
</template>

<script src="./Pagination.js"></script>

<style
    src="./Pagination.scss"
    lang="scss"
    scoped
></style>
