import Input from '../Input/Input.vue';

export default {
    name: 'SearchInput',

    props: {
        value: {
            type: String,
            required: true
        },
        name: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Enter search value'
        },
        error: {
            type: String,
            default: ''
        },
        onChange: {
            type: Function,
            required: true
        },
        onFocus: {
            type: Function,
            default: () => {}
        },
        onBlur: {
            type: Function,
            default: () => {}
        },
        onKeyPress: {
            type: Function,
            default: () => {}
        },
        onEnterKeyPress: {
            type: Function,
            default: null
        },
        onSearchClick: {
            type: Function,
            required: true
        },
        onClearClick: {
            type: Function,
            default: null
        }
    },

    components: {
        Input
    }
};
