<template>
    <div class="step">
        <slot />
    </div>
</template>

<script src="./Step.js"></script>

<style
    src="./Step.scss"
    lang="scss"
    scoped
></style>
