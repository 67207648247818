<template>
    <div>
        <slot />
    </div>
</template>

<script src="./Tab.js"></script>

<style
    src="./Tab.scss"
    lang="scss"
    scoped
></style>
