import Modal from '../Modal/Modal.vue';
import Button from '../Button/Button.vue';

export default {
    name: 'ConfirmModal',

    props: {
        text: {
            type: String,
            default: null
        },
        cancelButtonText: {
            type: String,
            default: 'Cancel'
        },
        confirmButtonText: {
            type: String,
            default: 'Ok'
        },
        onClose: {
            type: Function,
            required: true
        },
        onYes: {
            type: Function,
            required: true
        }
    },

    components: {
        Modal,
        Button
    }
};
