<template>
  <div
      class="select"
      :class="{
            select_state_active: value,
            select_type_withLabel: label,
            select_state_disabled: disabled,
            select_type_required: required,
            select_state_error: error
        }"
  >
    <div class="select__container">
      <select
          :id="id"
          class="select__field"
          :value="value"
          :name="name"
          @change="onChange"
      >
        <slot />
      </select>

      <label
          v-if="label"
          class="select__label"
          :for="id"
      >
        {{ label }}
      </label>
    </div>
    <div
        v-if="error"
        class="select__error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script src="./Select.js"></script>

<style
    src="./Select.scss"
    lang="scss"
    scoped
></style>
