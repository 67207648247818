import Preloader from '../Preloader/Preloader.vue';

export default {
    name: 'Button',

    props: {
        loading: {
            type: Boolean,
            default: false
        },
        theme: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fluid: {
            type: Boolean,
            default: false
        },
        onClick: {
            type: Function,
            default: () => {}
        }
    },

    components: {
        Preloader
    }
};
