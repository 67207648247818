import Icon from '../Icon/Icon.vue';

export default {
    name: 'Overlay',

    props: {
        onClose: {
            type: Function,
            required: true
        }
    },

    components: {
        Icon
    }
};
