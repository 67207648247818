export default {
    name: 'IconButton',

    props: {
        onClick: {
            type: Function,
            default: () => {}
        }
    }
};
