<template>
    <div class="toggler">
        <div class="toggler__container">
            <slot name="title" />

            <div
                class="toggler__trigger"
                @click="onTriggerClick"
            >
                <div
                    v-if="showContent"
                    class="toggler__trigger-hide"
                >
                    {{ hideButtonText }}
                </div>

                <div
                    v-else
                    class="toggler__trigger-show"
                >
                    {{ showButtonText }}
                </div>
            </div>
        </div>

        <div
            v-if="showContent"
            class="toggler__content-container"
        >
            <slot />
        </div>
    </div>
</template>

<script src="./Toggler.js"></script>

<style
    src="./Toggler.scss"
    lang="scss"
    scoped
></style>
