<template>
    <div
        class="stepper"
        :class="{
            stepper_state_completed: isCompleted
        }"
    >
        <div class="stepper__container">
            <div
                v-for="(step, index) in steps"
                :key="step.props.id"
                class="stepper__step"
                :class="{
                    stepper__step_state_active: activeStep === index || activeStep === step.props.id,
                    stepper__step_state_disabled: step.props.disabled,
                    stepper__step_state_completed: step.props.completed
                }"
            >
                <div
                    v-if="index !== 0"
                    class="stepper__step-line"
                ></div>

                <div class="stepper__step-label-container">
                    <div
                        class="stepper__step-num"
                        @click="onChange ? onChange(step.props.id ? step.props.id : index) : null"
                    >
                        {{ !step.props.completed ? index + 1 : null }}

                        <svg
                            v-if="step.props.completed"
                            class="stepper__step-num-icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 57 57"
                        >
                            <path d="M3.89 23.407l20.19 22.012 28.863-32.338" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="7.46852"/>
                        </svg>
                    </div>

                    <div
                        v-if="step.props.label"
                        class="stepper__step-label"
                        @click="onChange ? onChange(step.props.id ? step.props.id : index) : null"
                    >
                        {{ step.props.label }}
                    </div>
                </div>
            </div>
        </div>

        <slot />
    </div>
</template>

<script src="./Stepper.js"></script>

<style
    src="./Stepper.scss"
    lang="scss"
    scoped
></style>
