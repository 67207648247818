export default {
    name: 'Select',

    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            required: true
        },
        name: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ''
        },
        onChange: {
            type: Function,
            required: true
        }
    },

    data() {
        return {
            id: `select-${Math.random().toString(36).substring(2, 10)}`
        };
    }
};
