import { computed, ref } from 'vue';
import Input from '../Input/Input.vue';

export default {
    name: 'DataTable',

    props: {
        loading: {
            type: Boolean,
            default: false
        },
        overflow: {
            type: Boolean,
            default: false
        },
        stickyHeader: {
            type: Boolean,
            default: false
        },
        showPaginationInfo: {
            type: Boolean,
            default: false
        },
        filterable: {
            type: Boolean,
            default: false
        },
        filterPlaceholder: {
            type: String,
            default: null
        },
        headers: {
            type: Object,
            default: null
        },
        items: {
            type: Object,
            default: null
        },
        itemsName: {
            type: String,
            default: null
        },
        totalItems: {
            type: [String, Number],
            default: null
        },
        page: {
            type: [String, Number],
            default: null
        },
        itemsPerPage: {
            type: [String, Number],
            default: null
        },
        onScroll: {
            type: Function,
            default: () => {}
        },
        onRowClick: {
            type: Function,
            default: null
        }
    },

    components: {
        Input
    },

    setup(props) {
        const initialSortDirection = 'asc';
        const finalSortDirection = 'desc';
        const sortDirection = ref(null);
        const sortBy = ref(null);
        const filterBy = ref(null);

        const paginationInfoMessage = computed(() => {
            const itemsCount = props.items.length;
            const isFirstPage = Number(props.page) === 1;
            const from = (props.page - 1) * props.itemsPerPage;
            const to = from + itemsCount;

            let visibleItemsMsg = `${isFirstPage ? 1 : from}-${to}`;
            let itemsCountMsg = '';
            const itemsNameMsg = ` ${props.itemsName || 'items'}`;

            if (props.totalItems) {
                const totalPages = Math.ceil(props.totalItems / props.itemsPerPage);
                const onlyOnePage = totalPages === 1;

                if (onlyOnePage) {
                    visibleItemsMsg = props.totalItems;
                }

                itemsCountMsg = ` of ${props.totalItems}`;
            }

            return `Displaying: ${visibleItemsMsg}${itemsCountMsg}${itemsNameMsg}`;
        });

        const filterItems = (items) => items
            .filter((item) => Object.entries(item).some(([key, value]) => {
                const header = props.headers.find((h) => h.value === key);

                if (!header || !header.filterable) {
                    return false;
                }

                const { getValueToFilter } = header;
                const valueToFilter = String(getValueToFilter ? getValueToFilter(value) : value);

                return valueToFilter.toLowerCase().trim().includes(filterBy.value);
            }));

        const sortItems = (items) => {
            const { getValueToSort, sortAsDate } = props.headers.find(({ value }) => value === sortBy.value);

            return [...items].sort((a, b) => {
                const modifier = sortDirection.value === 'asc' ? 1 : -1;
                let prev = getValueToSort ? getValueToSort(a[sortBy.value]) : a[sortBy.value];
                let current = getValueToSort ? getValueToSort(b[sortBy.value]) : b[sortBy.value];

                if (sortAsDate) {
                    prev = Date.parse(prev);
                    current = Date.parse(current);
                }

                if (prev < current) return -1 * modifier;
                if (prev > current) return 1 * modifier;
                return 0;
            });
        };

        const itemsToDisplay = computed(() => {
            let { items } = props;

            if (filterBy.value) {
                items = filterItems(items);
            }
            if (sortBy.value) {
                items = sortItems(items);
            }

            return items;
        });

        const changeSortState = (value) => {
            if (sortBy.value !== value) {
                sortBy.value = value;
                sortDirection.value = initialSortDirection;
            } else if (sortDirection.value === initialSortDirection) {
                sortDirection.value = finalSortDirection;
            } else {
                sortBy.value = null;
                sortDirection.value = initialSortDirection;
            }
        };

        const changeFilterState = ({ target }) => {
            filterBy.value = target.value.toLowerCase().trim();
        };

        return {
            paginationInfoMessage,
            initialSortDirection,
            sortDirection,
            sortBy,
            filterBy,
            itemsToDisplay,
            changeSortState,
            changeFilterState
        };
    }
};
