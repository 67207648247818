<template>
    <div
        class="table-cell"
        :class="{
            'table-cell_type_custom-width': width
        }"
        :style="{
            width: width
        }"
    >
        <slot />
    </div>
</template>

<script src="./TableCell.js"></script>

<style
    src="./TableCell.scss"
    lang="scss"
    scoped
></style>
