import { computed } from 'vue';

export default {
    name: 'Stepper',

    props: {
        activeStep: {
            type: [Number, String],
            default: null
        },
        isCompleted: {
            type: Boolean,
            default: false
        },
        onChange: {
            type: Function,
            default: null
        }
    },

    setup(props, { slots }) {
        const steps = computed(() => slots.default()[0].children);

        return { steps };
    }
};
