import { cloneVNode } from 'vue';

export default {
    name: 'Tabs',

    props: {
        activeTab: {
            type: [Number, String],
            default: null
        },
        onChange: {
            type: Function,
            required: true
        }
    },

    setup(props, { slots }) {
        const children = slots.default ? slots.default() : [];
        const tabs = children.filter((vnode) => {
            if (!vnode.props) {
                return false;
            }

            return cloneVNode(vnode);
        });

        return {
            tabs
        };
    }
};
