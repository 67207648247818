export default {
    name: 'TableRow',

    props: {
        head: {
            type: Boolean,
            default: false
        },

        sticky: {
            type: Boolean,
            default: false
        }
    }
};
