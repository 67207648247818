<template>
    <div class="title">
        <slot />
    </div>
</template>

<script src="./Title.js"></script>

<style
    src="./Title.scss"
    lang="scss"
    scoped
></style>
