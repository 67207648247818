export default {
    name: 'Tab',

    props: {
        id: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
