export default {
    name: 'TableCell',

    props: {
        width: {
            type: String,
            default: null
        }
    }
};
