<template>
    <div
        class="table-row"
        :class="{
            'table-row_type_head': head,
            'table-row_type_sticky': sticky
        }"
    >
        <slot />
    </div>
</template>

<script src="./TableRow.js"></script>

<style
    src="./TableRow.scss"
    lang="scss"
    scoped
></style>
