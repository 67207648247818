<template>
    <div
        class="context-menu-item"
        :class="{
            'context-menu-item_state_disabled': disabled
        }"
        @click="onClick"
    >
        <div
            v-if="$slots.icon"
            class="context-menu-item__icon"
        >
            <slot name="icon" />
        </div>

        <slot />
    </div>
</template>

<script src="./ContextMenuItem.js"></script>

<style
    src="./ContextMenuItem.scss"
    lang="scss"
    scoped
></style>
