<template>
    <div
        class="icon-button"
        @click="onClick"
    >
        <slot />
    </div>
</template>

<script src="./IconButton.js"></script>

<style
    src="./IconButton.scss"
    lang="scss"
    scoped
></style>
