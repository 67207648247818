import Icon from '../Icon/Icon.vue';

export default {
    name: 'Tag',

    props: {
        onRemove: {
            type: Function,
            default: null
        }
    },

    components: {
        Icon
    }
};
