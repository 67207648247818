<template>
    <teleport to="#overlay">
        <div
            class="overlay"
            data-qaid="overlay"
        >
            <div
                class="overlay__close-ico"
                data-qaid="overlay-close-button"
                title="Close"
                @click="onClose"
            />

            <div class="overlay__container">
                <slot />
            </div>
        </div>
    </teleport>
</template>

<script src="./Overlay.js"></script>

<style
    src="./Overlay.scss"
    lang="scss"
    scoped
></style>
