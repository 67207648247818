export default {
    name: 'Step',

    props: {
        id: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        completed: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
};
