import {
    onMounted,
    onUnmounted
} from 'vue';

export default {
    name: 'Popover',

    props: {
        anchorEl: {
            required: true
        },
        container: {
            type: Object,
            default: null
        },
        onClose: {
            type: Function,
            required: true
        }
    },

    setup(props) {
        onMounted(() => {
            window.addEventListener('orientationchange', props.onClose);
        });

        onUnmounted(() => {
            window.removeEventListener('orientationchange', props.onClose);
        });
    },

    computed: {
        position() {
            return this.calculatePosition();
        }
    },

    methods: {
        calculatePosition() {
            const anchorElRect = this.anchorEl.getBoundingClientRect();

            return {
                top: `${anchorElRect.top + anchorElRect.height + window.scrollY}px`,
                right: `${window.innerWidth - anchorElRect.right}px`
            };
        },

        handlePopoverClick({ target }) {
            const { container } = this.$refs;

            if (container && !container.contains(target)) {
                this.onClose();
            }
        }
    }
};
