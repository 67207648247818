import {
    onMounted,
    onUnmounted
} from 'vue';

export default {
    name: 'Modal',

    props: {
        onClose: {
            type: Function,
            required: true
        }
    },

    setup() {
        onMounted(() => {
            document.documentElement.style.overflow = 'hidden';
            document.body.style.overflow = 'hidden';
        });

        onUnmounted(() => {
            document.documentElement.style.overflow = null;
            document.body.style.overflow = null;
        });
    },

    methods: {
        handleModalClick({ target }) {
            const { container } = this.$refs;

            if (container && !container.contains(target)) {
                this.onClose();
            }
        }
    }
};
