<template>
    <div class="section">
        <div class="section__header">
            <div class="section__icon-container">
                <slot name="icon" />
            </div>

            <div class="section__title-container">
                <div class="section__title">
                    {{ title }}
                </div>

                <div class="section__description">
                    {{ description }}
                </div>
            </div>
        </div>

        <div class="section__content">
            <slot />
        </div>
    </div>
</template>

<script src="./Section.js"></script>

<style
    src="./Section.scss"
    lang="scss"
    scoped
></style>
