<template>
    <div
        class="table"
        :class="{
            'table_type_overflow': overflow
        }"
    >
        <slot />
    </div>
</template>

<script src="./Table.js"></script>

<style
    src="./Table.scss"
    lang="scss"
    scoped
></style>
