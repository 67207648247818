export default {
    name: 'Link',

    props: {
        href: {
            type: String,
            default: null
        },
        target: {
            type: String,
            default: ''
        },
        onClick: {
            type: Function,
            default: () => {}
        }
    }
};
