<template>
    <div
        class="dropdown"
        :class="{
            dropdown_state_opened: showDropdown,
            dropdown_state_disabled: disabled,
            dropdown_type_required: required
        }"
    >
        <div
            v-if="label"
            class="dropdown__label"
        >
            {{ label }}
        </div>

        <div
            class="dropdown__container"
            @click="openDropdown"
        >
            <input
                v-if="isSearchActive"
                ref="input"
                class="dropdown__search-field"
                :value="searchValue"
                @input="onSearchInputChange"
            />

            <div
                v-else
                class="dropdown__selected-option"
            >
                {{ selected ? selected.label : '' }}
            </div>
        </div>

        <div
            v-if="error"
            class="dropdown__error"
        >
            {{ error }}
        </div>

        <div
            v-if="hint"
            class="dropdown__hint"
        >
            {{ hint }}
        </div>

        <template v-if="showDropdown">
            <div
                class="dropdown__dropdown-overlay"
                @click="closeDropdown"
            />

            <div class="dropdown__dropdown">
                <div
                    v-if="!filteredItems.length"
                    class="dropdown__no-results-msg"
                >
                    No results found
                </div>

                <div
                    v-else
                    v-for="(item, index) in filteredItems"
                    :key="item.value || index"
                >
                    <div
                        v-if="item.children"
                        class="dropdown__optgroup"
                    >
                        <div
                            v-if="item.title"
                            class="dropdown__optgroup-label"
                        >
                            {{ item.title }}
                        </div>

                        <div
                            v-for="option in item.children"
                            :key="option.value"
                            class="dropdown__option"
                            @click="(event) => { onOptionClick(option, event); }"
                        >
                            {{ option.label }}
                        </div>
                    </div>

                    <div
                        v-else
                        class="dropdown__option"
                        @click="(event) => { onOptionClick(item, event); }"
                    >
                        {{ item.label }}
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script src="./Dropdown.js"></script>

<style
    src="./Dropdown.scss"
    lang="scss"
    scoped
></style>
