import { ref } from 'vue';

export default {
    name: 'Dropdown',

    props: {
        items: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            default: null
        },
        value: {
            type: String,
            required: true
        },
        name: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        hint: {
            type: String,
            default: null
        },
        error: {
            type: String,
            default: null
        },
        autosuggest: {
            type: Boolean,
            default: false
        },
        onChange: {
            type: Function,
            required: true
        }
    },

    setup({
        items,
        value: valueProp,
        name,
        disabled,
        autosuggest,
        onChange
    }) {
        const input = ref(null);
        const filteredItems = ref(items);
        const selected = ref(null);
        const searchValue = ref('');
        const showDropdown = ref(false);
        const isSearchActive = ref(false);

        if (typeof valueProp !== 'undefined' && valueProp !== null) {
            selected.value = items.find((item) => item.value === valueProp);
        }

        const openDropdown = () => {
            if (!disabled) {
                showDropdown.value = true;

                if (autosuggest) {
                    isSearchActive.value = true;

                    setTimeout(() => {
                        input.value.focus();
                    });
                }
            }
        };

        const filterItems = (value) => {
            if (value) {
                const regex = new RegExp(`\\b${value}`, 'i');

                const processedItems = items.filter((item) => {
                    const test = item.label.match(regex);

                    return test && test.index === 0;
                });

                if (processedItems.length) {
                    processedItems.sort((a, b) => b.priority - a.priority);
                }

                filteredItems.value = processedItems;
            } else {
                filteredItems.value = items;
            }
        };

        const closeDropdown = (event) => {
            event.stopPropagation();

            showDropdown.value = false;

            if (autosuggest) {
                searchValue.value = '';

                filterItems('');

                isSearchActive.value = false;
            }
        };

        const onSearchInputChange = (event) => {
            const { value } = event.target;

            searchValue.value = value;

            filterItems(value);
        };

        const onOptionClick = (option, event) => {
            if (!disabled) {
                selected.value = option;

                if (autosuggest) {
                    searchValue.value = option.label;

                    filterItems(option.label);
                }

                onChange({
                    name,
                    value: option.value
                });

                closeDropdown(event);
            }
        };

        return {
            input,
            filteredItems,
            selected,
            searchValue,
            showDropdown,
            isSearchActive,
            openDropdown,
            closeDropdown,
            onSearchInputChange,
            onOptionClick
        };
    }
};
