<template>
    <div class="tag">
        <div class="tag__content">
            <slot />
        </div>
        <div
            v-if="onRemove"
            class="tag__remove-icon"
            title="Remove"
            @click="onRemove"
        >
            <Icon name="close" />
        </div>
    </div>
</template>

<script src="./Tag.js"></script>

<style
    src="./Tag.scss"
    lang="scss"
    scoped
></style>
