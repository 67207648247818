export default {
    name: 'Avatar',

    props: {
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            default: ''
        }
    }
};
