export default {
    name: 'Table',

    props: {
        overflow: {
            type: Boolean,
            default: false
        }
    }
};
