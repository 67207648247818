<template>
    <div class="popover">
        <div
            class="popover__overlay"
            @click="handlePopoverClick"
        ></div>
        <div
            ref="container"
            class="popover__container"
            :style="{ top: position.top, right: position.right }"
        >
            <slot />
        </div>
    </div>
</template>

<script src="./Popover.js"></script>

<style
    src="./Popover.scss"
    lang="scss"
    scoped
></style>
