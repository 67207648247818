<template>
    <div class="avatar">
        <img
            class="avatar__img"
            :src="src"
            :alt="alt"
        >
    </div>
</template>

<script src="./Avatar.js"></script>

<style
    src="./Avatar.scss"
    lang="scss"
    scoped
></style>
