<template>
    <teleport to="#modal">
        <div
            class="modal"
            @click="handleModalClick"
        >
            <div
                ref="container"
                class="modal__container"
            >
                <div
                    class="modal__close-ico"
                    title="Close"
                    @click="onClose"
                />

                <slot />
            </div>
        </div>
    </teleport>
</template>

<script src="./Modal.js"></script>

<style
    src="./Modal.scss"
    lang="scss"
    scoped
></style>
