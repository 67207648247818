<template>
    <i class="icon">{{name}}</i>
</template>

<script src="./Icon.js"></script>

<style
    src="./Icon.scss"
    lang="scss"
    scoped
></style>
