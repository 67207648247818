<template>
    <div class="tabs">
        <div class="tabs__buttons-container">
            <div
                v-for="(tab, index) in tabs"
                :key="index"
                class="tabs__button"
                :class="{
                    tabs__button_state_active: activeTab === index || activeTab === tab.props.id,
                    tabs__button_state_disabled: tab.props.disabled
                }"
                @click="onChange(tab.props.id ? tab.props.id : index)"
            >
                {{ tab.props.title }}
            </div>
        </div>

        <slot />
    </div>
</template>

<script src="./Tabs.js"></script>

<style
    src="./Tabs.scss"
    lang="scss"
    scoped
></style>
