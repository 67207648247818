<template>
    <a
        class="link"
        :href="href"
        :target="target"
        @click="onClick"
    >
        <slot />
    </a>
</template>

<script src="./Link.js"></script>

<style
    src="./Link.scss"
    lang="scss"
    scoped
></style>
