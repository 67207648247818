<template>
    <div class="search-input">
        <Input
            ref="input"
            :value="value"
            :name="name"
            :placeholder="placeholder"
            :error="error"
            :onChange="onChange"
            :onFocus="onFocus"
            :onBlur="onBlur"
            :onKeyPress="onKeyPress"
            :onEnterKeyPress="onEnterKeyPress"
        />

        <div
            v-if="onClearClick && value"
            class="search-input__button  search-input__button_type_clear"
            title="Clear search"
            @click="onClearClick"
        >
            <div class="search-input__clear-icon" />
        </div>

        <div
            class="search-input__button"
            title="Search"
            @click="onSearchClick"
        >
            <div class="search-input__search-icon">
                <div class="search-input__search-icon-circle" />
                <div class="search-input__search-icon-rectangle" />
            </div>
        </div>
    </div>
</template>

<script src="./SearchInput.js"></script>

<style
    src="./SearchInput.scss"
    lang="scss"
    scoped
></style>
