<template>
    <Modal
        :onClose="onClose"
    >
        <div class="confirm-modal">
            <div class="confirm-modal__text">
                {{text}}

                <slot />
            </div>

            <div class="confirm-modal__buttons-container">
                <div class="confirm-modal__cancel-button-holder">
                    <Button
                        theme="transparent"
                        :onClick="onClose"
                    >
                        {{cancelButtonText}}
                    </Button>
                </div>

                <Button
                    :onClick="onYes"
                >
                    {{confirmButtonText}}
                </Button>
            </div>
        </div>
    </Modal>
</template>

<script src="./ConfirmModal.js"></script>

<style
    src="./ConfirmModal.scss"
    lang="scss"
    scoped
></style>
