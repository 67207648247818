import { computed } from 'vue';

export default {
    name: 'Pagination',

    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        totalPages: {
            type: [Number, String],
            required: true
        },
        totalItems: {
            type: [Number, String],
            default: null
        },
        page: {
            type: [Number, String],
            required: true
        },
        itemsCount: {
            type: [Number, String],
            required: true
        },
        itemsPerPage: {
            type: [Number, String],
            required: true
        },
        onChange: {
            type: Function,
            required: true
        }
    },

    setup(props) {
        const onlyOnePage = computed(() => Number(props.totalPages) === 1);
        const isFirstPage = computed(() => Number(props.page) === 1);
        const isLastPage = computed(() => Number(props.totalPages) === Number(props.page));

        const paginationMessage = computed(() => {
            const from = (props.page - 1) * props.itemsPerPage;
            const to = from + props.itemsCount;

            const visibleItemsMsg = `${isFirstPage.value ? 1 : from}-${to}`;
            let itemsCountMsg = '';

            if (props.totalItems) {
                itemsCountMsg = ` of ${props.totalItems}`;
            }

            return `${visibleItemsMsg}${itemsCountMsg}`;
        });

        return {
            onlyOnePage,
            isFirstPage,
            isLastPage,
            paginationMessage
        };
    }
};
