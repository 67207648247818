<template>
    <div class="preloader"></div>
</template>

<script src="./Preloader.js"></script>

<style
    src="./Preloader.scss"
    lang="scss"
    scoped
></style>
